import { Color } from '@Entity/Color';
import { uploadCanvasPreview } from '@Feature/Canvas/Api/FileRepository';
import { Mode } from '@Type/canvas';
import { message } from 'antd';
import * as THREE from 'three';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier.js';
import { InputDeviceOverride } from './InputDeviceOverride';

import {
  addShapeToCanvas,
  overwriteCanvasContent,
  updateCanvasPreviewFilePath,
} from '@Feature/Canvas/Api/CanvasRepository';

const OUTLINE_COLOR = '#339EEC';
const Z_OFFSET_STEP = 0.001;
const EYEDROPPER_REGION_PIXEL_SIZE = 9;

export class CanvasHelper {
  canvasId: string;
  canvasCreatorId: string;
  scene: THREE.Scene;
  camera: THREE.OrthographicCamera;
  renderer: THREE.WebGLRenderer;
  container: HTMLDivElement;
  activeMode: Mode = 'lasso';
  activeColor: Color = Color.from('#858585');
  inputDeviceOverride: InputDeviceOverride;
  drawnVertices: THREE.Vector2[] = [];
  drawnLines: THREE.Line[] = [];
  zOffsetShapeOutline = 0;
  zOffsetShape = 0;
  isDrawing = false;
  setDrawingState: (isDrawing: boolean) => void;
  activeDrawingPointerId: number | null = null;

  constructor(
    container: HTMLDivElement,
    canvasId: string,
    canvasCreatorId: string,
    serializedScene: object | null,
    shapeCount: number,
    setIsDrawing: (isDrawing: boolean) => void
  ) {
    this.container = container;
    this.canvasId = canvasId;
    this.canvasCreatorId = canvasCreatorId;
    this.setDrawingState = setIsDrawing;

    this.camera = this.initializeCamera();
    this.renderer = this.initializeRenderer();
    this.appendRendererToCanvas();

    this.inputDeviceOverride = new InputDeviceOverride(this.camera, this.renderer, this.activeMode);
    this.inputDeviceOverride.doOverride();

    if (!serializedScene) {
      this.scene = new THREE.Scene();
      this.persistEmptyCanvas().catch((error) => {
        console.error('Error persisting empty canvas', error);
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.scene = new THREE.ObjectLoader().parse(serializedScene);
    }
    this.refreshScene();

    this.initializeDraw();
    this.initializeWindowListeners();
    this.initializeZOffset(shapeCount || 0);
  }

  private initializeZOffset(shapeCount: number) {
    this.zOffsetShape = shapeCount * Z_OFFSET_STEP;
    this.zOffsetShapeOutline = shapeCount * Z_OFFSET_STEP;
  }

  public setActiveMode(mode: Mode) {
    this.activeMode = mode;
    this.inputDeviceOverride.setMode(this.activeMode);
  }

  public setActiveColor(color: Color) {
    this.activeColor = color;
  }

  private appendRendererToCanvas() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.container.appendChild(this.renderer.domElement);
  }

  public refreshScene = (): void => {
    requestAnimationFrame(this.refreshScene);
    this.renderer.render(this.scene, this.camera);
  };

  private initializeCamera() {
    const leftBound: number = window.innerWidth * -1;
    const rightBound: number = window.innerWidth;
    const topBound: number = window.innerHeight;
    const bottomBound: number = window.innerHeight * -1;
    const near = 1;
    const far = 1000;
    const camera = new THREE.OrthographicCamera(leftBound, rightBound, topBound, bottomBound, near, far);

    camera.position.set(0, 0, 5);

    return camera;
  }

  private initializeRenderer = (): THREE.WebGLRenderer => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
    const canvasColor = 0x545454;
    const opacity = 1.0;
    renderer.setClearColor(canvasColor, opacity);
    return renderer;
  };

  private convertPointerTwoDimensionalPositionToThreeDimensionalPosition = (x: number, y: number): THREE.Vector3 => {
    const vector = new THREE.Vector3();
    vector.set((x / window.innerWidth) * 2 - 1, -(y / window.innerHeight) * 2 + 1, 0.5);
    vector.unproject(this.camera);
    return vector;
  };

  private onPointerDown = (event: PointerEvent): void => {
    if (event.buttons === 2 || this.activeMode !== 'lasso' || this.pointerShouldNotDraw(event.pointerId)) {
      return;
    }

    const pointerToCameraVector = this.convertPointerTwoDimensionalPositionToThreeDimensionalPosition(
      event.clientX,
      event.clientY
    );
    this.drawnVertices = [new THREE.Vector2(pointerToCameraVector.x, pointerToCameraVector.y)];
  };

  private onPointerMove = (event: PointerEvent): void => {
    if (this.drawnVertices.length === 0) {
      return;
    }

    if (this.activeMode !== 'lasso') {
      this.onDrawingEnd();
      return;
    }

    if (this.pointerShouldNotDraw(event.pointerId)) {
      return;
    }

    this.onDrawingStart(event.pointerId);

    const pointerToCameraVector = this.convertPointerTwoDimensionalPositionToThreeDimensionalPosition(
      event.clientX,
      event.clientY
    );
    this.drawnVertices.push(new THREE.Vector2(pointerToCameraVector.x, pointerToCameraVector.y));

    const geometry = new THREE.BufferGeometry().setFromPoints(this.drawnVertices);
    const material = new THREE.LineBasicMaterial({ color: OUTLINE_COLOR });
    const drawnLine = new THREE.Line(geometry, material);
    drawnLine.position.z = this.zOffsetShapeOutline;
    this.scene.add(drawnLine);
    this.drawnLines.push(drawnLine);
  };

  private onPointerUp = (event: PointerEvent): void => {
    if (this.pointerShouldNotDraw(event.pointerId)) {
      return;
    }
    this.drawnVertices.push(this.drawnVertices[0]);

    if (this.activeMode !== 'lasso') {
      this.onDrawingEnd();
      return;
    }

    if (this.drawnVertices?.length > 2) {
      const finalShape = this.createShape();
      finalShape.renderOrder = this.zOffsetShape;
      this.scene.add(finalShape);

      this.zOffsetShape += Z_OFFSET_STEP;
      this.zOffsetShapeOutline += Z_OFFSET_STEP;

      this.persistNewShape(finalShape).catch((error) => {
        console.error('Error persisting new shape', error);
      });
    }

    this.onDrawingEnd();
  };

  private onPointerCancel = (event: PointerEvent) => {
    if (this.activeDrawingPointerId && this.activeDrawingPointerId === event.pointerId) {
      this.onPointerUp(event);
    }
  };

  private onDrawingStart = (pointerId: number) => {
    this.setDrawingState(true);
    this.isDrawing = true;
    this.inputDeviceOverride.disableZoomAndPan();
    this.activeDrawingPointerId = pointerId;
  };

  private onDrawingEnd = () => {
    this.setDrawingState(false);
    this.isDrawing = false;
    this.inputDeviceOverride.enableZoomAndPan();
    this.activeDrawingPointerId = null;

    this.removeDrawnLine();
    this.drawnVertices = [];
  };

  private pointerShouldNotDraw = (pointerId: number) => {
    return this.isDrawing && this.activeDrawingPointerId !== pointerId;
  };

  private removeDrawnLine = (): void => {
    for (const drawnLine of this.drawnLines) {
      this.scene.remove(drawnLine);
    }
    this.drawnLines = [];
  };

  private createShape = (): THREE.Mesh => {
    const shape = new THREE.Shape(this.drawnVertices);
    const geometry = new THREE.ShapeGeometry(shape);
    const modifier = new SimplifyModifier();
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.activeColor.hexString),
      side: THREE.DoubleSide,
      transparent: true,
      opacity: this.activeColor.alpha,
    });

    return new THREE.Mesh(modifier.modify(geometry, 1), material);
  };

  private initializeDraw = (): void => {
    this.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
    this.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
    this.renderer.domElement.addEventListener('pointerup', this.onPointerUp);
    this.renderer.domElement.addEventListener('pointercancel', this.onPointerCancel);
  };

  public discardShape = (): void => {
    if (this.activeMode !== 'lasso') {
      return;
    }

    this.onDrawingEnd();
  };

  private handleWindowResize = (): void => {
    this.camera.left = window.innerWidth * -1;
    this.camera.right = window.innerWidth;
    this.camera.top = window.innerHeight;
    this.camera.bottom = window.innerHeight * -1;

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.camera.updateProjectionMatrix();
  };

  private initializeWindowListeners = (): void => {
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleWindowResize);
    window.addEventListener('native.hidekeyboard', this.handleWindowResize);
  };

  private persistEmptyCanvas = async () => {
    const serializedScene = this.scene.toJSON();
    serializedScene.object.children = [];
    await overwriteCanvasContent(this.canvasId, { ...serializedScene, materials: [], geometries: [] });
  };

  private persistNewShape = async (shape: THREE.Mesh) => {
    const serializedShape = shape.toJSON();
    const geometry = serializedShape.geometries[0];
    const material = serializedShape.materials[0];
    const object = serializedShape.object;

    addShapeToCanvas(this.canvasId, { geometry, material, object })
      .then(this.updateCanvasPreview)
      .catch((error) => {
        console.error('Error persisting new shape', error);
      });
  };

  private updateCanvasPreview = async () => {
    try {
      const preview = this.getBase64EncodedImageData();
      const filePath = `${this.canvasCreatorId}/${this.canvasId}.jpg`;
      const previewFilePath = await uploadCanvasPreview(filePath, preview);
      if (previewFilePath) {
        await updateCanvasPreviewFilePath(this.canvasId, previewFilePath);
      }
    } catch (error) {
      console.error(error);
      message.error('Canvas not saved - is your internet connection on?');
    }
  };

  private getBase64EncodedImageData(): string {
    return this.renderer.domElement.toDataURL('image/jpeg');
  }

  public getBase64SerializedScene(): string {
    return this.getBase64EncodedImageData();
  }

  public zoomIn = (): void => {
    this.inputDeviceOverride.handleZoomIn();
  };

  public zoomOut = (): void => {
    this.inputDeviceOverride.handleZoomOut();
  };

  public resetZoom = (): void => {
    this.inputDeviceOverride.handleResetZoom();
  };

  //~~~~~~~~~~CanvasReader~~~~~~~~~~~

  public getDefaultPixelColors = () => {
    const initialColor = 'rgb(133, 133, 133)';
    const pixelColorArray: string[][] = [];

    for (let i = 0; i < EYEDROPPER_REGION_PIXEL_SIZE; i++) {
      const row = [];
      for (let j = 0; j < EYEDROPPER_REGION_PIXEL_SIZE; j++) {
        row.push(initialColor);
      }
      pixelColorArray.push(row);
    }

    return pixelColorArray;
  };

  public getPixelColor = (x: number, y: number) => {
    if (this.renderer && this.renderer.domElement) {
      const pixelBuffer = this.createEyedropperPixelBuffer(x, y, 1);

      const r = pixelBuffer[0];
      const g = pixelBuffer[1];
      const b = pixelBuffer[2];

      return Color.from({ r, g, b }).hsla;
    }
  };

  public getPixelColorRegion = (centerX: number, centerY: number): string[][] => {
    const pixelBuffer = this.createEyedropperPixelBuffer(centerX, centerY, EYEDROPPER_REGION_PIXEL_SIZE);
    return this.getEyedropperColorsFromPaletteRegionArray(pixelBuffer);
  };

  private getEyedropperColorsFromPaletteRegionArray = (pixelBuffer: Uint8Array): string[][] => {
    if (this.isImpossibleColor(pixelBuffer.slice(0, 4))) {
      return this.getDefaultPixelColors();
    }

    const channelsPerPixel = 4;
    const numPixelsPerRow = EYEDROPPER_REGION_PIXEL_SIZE * channelsPerPixel;
    const numPixels = pixelBuffer.length / numPixelsPerRow;
    const pixelColors = [];

    for (let i = 0; i < numPixels; i++) {
      const pixelRow = [];
      for (let j = 0; j < EYEDROPPER_REGION_PIXEL_SIZE; j++) {
        const startIndex = (numPixels - i - 1) * numPixelsPerRow + j * channelsPerPixel;

        const red = pixelBuffer[startIndex];
        const green = pixelBuffer[startIndex + 1];
        const blue = pixelBuffer[startIndex + 2];

        const colorString = `rgb(${red}, ${green}, ${blue})`;
        pixelRow.push(colorString);
      }
      pixelColors.push(pixelRow);
    }

    return pixelColors;
  };

  private createEyedropperPixelBuffer = (x: number, y: number, regionSize: number): Uint8Array => {
    const gl = this.renderer.getContext();
    const pixelBuffer = new Uint8Array(regionSize ** 2 * 4);
    const correctedX = x - Math.floor(regionSize / 2);
    const correctedY = this.renderer.domElement.height - y - Math.floor(regionSize / 2);
    this.renderer.render(this.scene, this.camera);

    gl.readPixels(correctedX, correctedY, regionSize, regionSize, gl.RGBA, gl.UNSIGNED_BYTE, pixelBuffer);
    return pixelBuffer;
  };

  private isImpossibleColor = (pixelBuffer: Uint8Array) => {
    return pixelBuffer.reduce((sum, num) => (sum += num), 0) === 0;
  };

  //~~~~~~~~~~CanvasReader~~~~~~~~~~~
}
